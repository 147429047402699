import axios from 'axios'
import {toast} from 'react-toastify'
import * as actions from "./productSlice"
import { resetProductNumberStatus, updateProductNumber } from "../Account/accountSlice"
import getApiUri from '../../helper/getApiUri'

const apiUrl = getApiUri() + "/products"

export const createProduct = async (payload, dispatch) => {
    dispatch(actions.createProductStart())
    try {
        const {data} = await axios.post(apiUrl, payload)
        dispatch(actions.createProductSuccess(data.data))
        dispatch(updateProductNumber({ mode: 'increment' }))
        toast.success(data.message);
        return true
    } catch (error) {
        console.log(error)
        dispatch(actions.createProductFailure())
        if (error.response) {
            toast.error(error.response.data.message);
        } else {
            toast.error("Something went wrong!")
        }
        return false
    }
}

export const getProducts = async (payload, dispatch) => {
    dispatch(actions.getProductsStart())
    try {
        const {data} = await axios.get(apiUrl + `/${payload}`)
        dispatch(actions.getProductsSuccess(data.data))
        return true
    } catch (error) {
        dispatch(actions.getProductsFailure())
        return false
    }
}

export const getLastProductId = async (payload, dispatch) => {
    try {
        const {data} = await axios.get(apiUrl + `/latest/${payload}`)
        dispatch(updateProductNumber({mode: 'fetch', product_number: data.data}))
        return true
    } catch (error) {
        return false
    }
}

export const updateProduct = async (payload, dispatch) => {
    dispatch(actions.updateProductStart())
    try {
        const url = apiUrl + '/' + payload.id
        const {data} = await axios.put(url, payload.data)
        dispatch(actions.updateProductSuccess(data.data))
        toast.success(data.message)
        return true
    } catch (error) {
        console.log(error)
        dispatch(actions.updateProductFailure())
        return false
    }
}

export const deleteProduct = async (id, dispatch) => {
    dispatch(actions.deleteProductStart())
    try {
        const {data} = await axios.delete(apiUrl + "/" + id)
        dispatch(actions.deleteProductSuccess(id))
        toast.success(data.message)
        return true
    } catch (error) {
        toast.error("Something went wrong!");
        dispatch(actions.deleteProductFailure())
        return false
    }
}