import * as React from "react"
import { useState } from "react"
import "../../styles/table.css"
import {
    useReactTable,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    flexRender,
} from "@tanstack/react-table"
import { Button, HStack, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Container, useDisclosure, FormControl, FormLabel, Switch, VStack, Stack, Box } from "@chakra-ui/react"
import AddCell from "./AddCell"
import { IoBagOutline } from 'react-icons/io5'
import { CiDiscount1 } from "react-icons/ci";
import AddProductPopup from '../AddProductPopup'
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { resetRecentModeChange, updateDiscountsEnabled } from "../../redux/CreateDoc/createDocSlice"
import { updateItemize } from "../../redux/CreateDoc/createDocSlice"
import EditDiscountPopup from "../EditGlobalDiscountPopup"

// HR - TanFormTable is different than TanTable in that is does NOT have the global or column filters and it DOES have add new/existing buttons

export default function TanFormTable({ columns, defaultData, type, modalOpen, contentType, mode }) {
    const [data, setData] = useState(() => [...defaultData])
    const [originalData, setOriginalData] = useState(() => [...defaultData])
    const [editedRows, setEditedRows] = useState({})
    const { discounts_enabled } = useSelector((state) => state.createDoc)
    const [discountsEnabled, setDiscountsEnabled] = useState(() => discounts_enabled)
    const [globalDiscountOptions, setGlobalDiscountOptions] = useState({
        use_cost: false,
        use_markup: false,
        percentage: 0,
    })
    const { products } = useSelector((state) => state.createDoc)
    const { isOpen: isProoductOpen, onOpen: onProductOpen, onClose: onProductClose } = useDisclosure()
    const { isOpen: isDiscountOpen, onOpen: onDiscountOpen, onClose: onDiscountClose } = useDisclosure()
    const dispatch = useDispatch()

    useEffect(() => {
        // HR - only call this function if the redux state value of discounts_ewnabled differs from the useState value (prevents unecessary discount_option resets)
        if (discountsEnabled !== discounts_enabled) {
            handleGlobalDiscountChange()
        }
    }, [discountsEnabled])

    // HR - when user turns on discount option, reset each product's calculated_price to the original price and set each product's discount to current global value
    function handleGlobalDiscountChange() {
        dispatch(updateDiscountsEnabled({ value: discountsEnabled, globalDiscountOptions: globalDiscountOptions }))
        products.forEach(product => {
            dispatch(updateItemize({
                ...product,
                calculated_price: product.list_price,
                discount_options: globalDiscountOptions
            }))
        })
        dispatch(resetRecentModeChange())
    }

    useEffect(() => {
        setData(defaultData)
        setOriginalData(defaultData)
    }, [defaultData])

    const table = useReactTable({
        data,
        columns,
        initialState: {
            pagination: {
                pageSize: 100,
            }
        },
        meta: {
            editedRows,
            setEditedRows,
            revertData: (rowIndex, revert) => {
                if (revert) {
                    setData((old) =>
                        old.map((row, index) =>
                            index === rowIndex ? originalData[rowIndex] : row
                        )
                    );
                } else {
                    setOriginalData((old) =>
                        old.map((row, index) => (index === rowIndex ? data[rowIndex] : row))
                    );
                }
            },
            updateData: (rowIndex, columnId, value) => {
                setData((old) =>
                    old.map((row, index) => {
                        if (index === rowIndex) {
                            return {
                                ...old[rowIndex],
                                [columnId]: value,
                            };
                        }
                        return row;
                    })
                );
            },
            // addRow: () => {
            //     // TODO - this will need to change for each table
            //     const newRow = {
            //         studentId: Math.floor(Math.random() * 10000),
            //         name: "",
            //         dateOfBirth: "",
            //         major: "",
            //     };
            //     const setFunc = (old) => [...old, newRow];
            //     setData(setFunc);
            //     setOriginalData(setFunc);
            //     table.setPageSize(data.length - 1)
            // },
            removeRow: (rowIndex) => {
                const setFilterFunc = (old) =>
                    old.filter((_row, index) => index !== rowIndex);
                setData(setFilterFunc);
                setOriginalData(setFilterFunc);
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
        autoResetPageIndex: false
    })


    return (
        <VStack spacing={'2rem'}>
            {(contentType !== "sortable" && mode !== 'view') &&
                <Stack spacing={"2rem"} width={'fit-content'} display={'flex'} direction={['column', 'column', 'row']} alignItems={'center'}>
                    {(type === "Quote" || type === "Invoice") &&
                        <Box>
                            <FormControl display='flex' alignItems='center'>
                                <FormLabel mb='0' fontSize={'lg'}>
                                    {discountsEnabled ? "Discounts/Markups On" : "Discounts/Markups Off"}
                                </FormLabel>
                                <Switch size={'lg'} colorScheme={'orange'} isChecked={discountsEnabled} onChange={(e) => setDiscountsEnabled(e.target.checked)} />
                            </FormControl>
                            {discountsEnabled &&
                                <EditDiscountPopup isOpen={isDiscountOpen} onClose={onDiscountClose} globalDiscountOptions={globalDiscountOptions} setGlobalDiscountOptions={setGlobalDiscountOptions}
                                    handleGlobalDiscountChange={handleGlobalDiscountChange} />
                            }
                        </Box>
                    }
                    {discountsEnabled &&  <Box flex={1}><Button variant={"solid"} colorScheme={"orange"} flex={1} onClick={onDiscountOpen} leftIcon={<CiDiscount1  />}>Edit Global Discount</Button></Box>}
                    <Box flex={1}> <Button variant={"solid"} colorScheme={"blue"} onClick={modalOpen} leftIcon={<IoBagOutline />}>Existing Product</Button></Box>
                    <AddCell type={contentType} openModal={onProductOpen} />
                </Stack>
            }
            <HStack spacing={"2rem"}>
                <TableContainer backgroundColor={"whiteAlpha.100"} border={discountsEnabled ? "solid #dd6b20 3px" : "solid #4A5568 1px"} borderRadius={"1rem"} overflowY={"auto"} height={"40vh"} width={["90vw", "90vw", "90vw", "60vw"]}>
                    <Table variant={"striped"} style={{ border: "none" }} colorScheme="blue">
                        <Thead>
                            {table.getHeaderGroups().map(headerGroup => (
                                <Tr key={headerGroup.id}>
                                    {headerGroup.headers.map(header => {
                                        return (
                                            <Th key={header.id} colSpan={header.colSpan} borderRight={"none"}>
                                                {header.isPlaceholder ? null : (
                                                    <>
                                                        <div
                                                            {...{
                                                                className: header.column.getCanSort()
                                                                    ? "cursor-pointer select-none"
                                                                    : "",
                                                                onClick: header.column.getToggleSortingHandler()
                                                            }}
                                                        >
                                                            {flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                            </Th>
                                        )
                                    })}
                                </Tr>
                            ))}
                        </Thead>
                        <Tbody>
                            {table.getRowModel().rows.map(row => {
                                return (
                                    <Tr key={row.id} width={'fit-content'}>
                                        {row.getVisibleCells().map(cell => {
                                            return (
                                                <Td key={cell.id} padding={'0.7rem'} w={'fit-content'}>
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )}
                                                </Td>
                                            )
                                        })}
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </HStack>
            <AddProductPopup isOpen={isProoductOpen} onClose={onProductClose} />
        </VStack>
    )
}