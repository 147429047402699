import { Select } from "@chakra-ui/react"
import DebouncedInput from "./DebouncedInput"

export default function Filter({ column }) {

    const columnFilterValue = column.getFilterValue()
    const type = column.columnDef.type
    return type === "select" ? (
        <Select
            onChange={e => column.setFilterValue(e.target.value)}
            value={columnFilterValue?.toString()}
        >
            <option key={"empty"} value={""} selected></option>
            {column.columnDef.options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </Select>
    ) : (
        <DebouncedInput
            type="text"
            value={columnFilterValue ?? ""}
            onChange={value => column.setFilterValue(value)}
            label={column.columnDef.header}
            list={column.id + "list"}
            width={"6rem"}
        />
    )
}