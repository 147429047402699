import {
    Box, Flex, HStack, Icon, useColorModeValue, Text, useDisclosure, Menu, MenuButton, MenuItem, MenuList, IconButton, useBreakpointValue, Stack,
    Collapse
} from "@chakra-ui/react"

import { NavLink, useNavigate } from "react-router-dom"
import {
    HamburgerIcon,
    CloseIcon,
} from "@chakra-ui/icons"
import { useDispatch } from "react-redux"
import { login } from "../redux/Auth/authCalls"
import { useGoogleLogin } from '@react-oauth/google';
import GoogleButton from "./GoogleButton"
import ColorModeToggle from "./ThemeButton"

// HR - signed-out drawer icons
const NoUserItems = [
    { name: "Features", link: "/features" },
    { name: "Pricing", link: "/pricing" },
    { name: "About Us", link: "/about" },
    { name: "Contact Us", link: "/contactus" },
    { name: "Signup Company", link: "/signup/company" },
    { name: "Signup User", link: "/signup/user" },
]


export default function NavSignedOut() {
    const { isOpen, onToggle } = useDisclosure()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const googleLogin = useGoogleLogin({
        onSuccess: tokenResponse => {
            login(tokenResponse.access_token, dispatch)
            navigate("/")
        }
    })

    return (
        <Box>
            <Flex
                bg={useColorModeValue("white", "gray.800")}
                color={useColorModeValue("gray.800", "white")}
                minH={"60px"}
                py={{ base: 2 }}
                px={{ base: 4 }}
                borderBottom={1}
                borderStyle={"solid"}
                borderColor={useColorModeValue("gray.200", "gray.900")}
                align={"center"}
            >
                <Flex
                    flex={{ base: 1, md: "auto" }}
                    ml={{ base: -2 }}
                    display={{ base: "flex", lg: "none" }}
                >
                    <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                        }
                        variant={"ghost"}
                        aria-label={"Toggle Navigation"}
                    />
                </Flex>
                <Flex flex={{ base: 1 }} justify={{ base: "center", lg: "start" }}>
                    <NavLink to="/" style={{ marginRight: '1rem' }}>
                        <Text
                            textAlign={useBreakpointValue({ base: "center", lg: "left" })}
                            fontSize="2xl"
                            fontFamily="monospace"
                            fontWeight="bold"
                        >
                            RapidToolz
                        </Text>
                    </NavLink>

                    <Flex display={{ base: "none", lg: "flex" }} ml={10}>
                        <DesktopNav />
                    </Flex>
                </Flex>

                <Stack
                    flex={{ base: 1, lg: 0 }}
                    justify={"flex-end"}
                    direction={"row"}
                    spacing={2}
                >
                    <ColorModeToggle />
                    <GoogleButton onClickFunction={googleLogin} phrase={"Login"} />
                </Stack>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav />
            </Collapse>
        </Box>
    )
}

const DesktopNav = () => {

    return (
        <Stack direction={"row"} spacing={2}>
            {NoUserItems.map((navItem, index) => (
                <NavLink key={index} to={navItem.link} >
                    <NavItem>
                        {navItem.name}
                    </NavItem>
                </NavLink>
            ))}
        </Stack>
    )
}

const MobileNav = () => {
    return (
        <Stack
            bg={useColorModeValue("white", "gray.800")}
            p={4}
            display={{ lg: "none" }}
        >
            {NoUserItems.map((navItem, index) => {
                return (
                    <NavLink to={navItem.link} >
                        <NavItem style={{ margin: "1rem" }} >
                            {navItem.name}
                        </NavItem>
                    </NavLink>
                )
            })}
        </Stack>
    )
}

const NavItem = ({ icon, children, ...rest }) => { // each of the sidebar buttons
    return (
        <Box
            style={{ textDecoration: "none" }}
            _focus={{ boxShadow: "none" }}
        >
            <Flex
                align="center"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    color: "cyan.400"
                }}
                {...rest}
            >
                {children}
            </Flex>
        </Box>
    )
}

