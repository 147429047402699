import {
    Text,
    View,
    StyleSheet
} from '@react-pdf/renderer';
import { textStyles, otherStyles } from "../../styles/PDFStyles"
import Table from './PDF-Table/Table';

const Subtitle = ({ children, ...props }) => (
    <Text style={textStyles["Subtitle"]} {...props}>
        {children}
    </Text>
);

const styles = StyleSheet.create({
    contentMargin: {
        marginBottom: 12.5
    },
});

// HR - function is used to create newLines for spacing
function SpaceText(num) {
    var returnString = ''
    for (let i = 0; i < Number(num); i++) {
        returnString += '\n'
    }
    return returnString
}

const QuoteHeader = ({ type, user_name, date, doc_number, cName, cAddress_1, cCity, cState, cZip, cCountry, cPhone, cEmail, shipping_content, content, header, discounts_enabled, total, lastTable, currency, show_description }) => {
    return (
        <View>
            <View>
                <Text style={textStyles["Center Text"]}>{user_name}</Text>
                <Text style={textStyles["Center Text"]}>{date}</Text>
                <Text style={textStyles["Center Text"]}>Quote #: {' '} {doc_number}</Text>
            </View>

            <View style={otherStyles.Row}>
                <View style={otherStyles['Left Side']}>
                    <Text style={textStyles.Header}>Bill To</Text>
                    <Text style={textStyles["Default Text"]}> <Text style={textStyles["Bold Underline Text"]}>Company Name:</Text>  {' '}{cName}</Text>
                    <Text style={textStyles["Default Text"]}> <Text style={textStyles["Bold Underline Text"]}>Street Address:</Text> {' '}{cAddress_1}</Text>
                    <Text style={textStyles["Default Text"]}> <Text style={textStyles["Bold Underline Text"]}>City, State, Zip:</Text> {' '}{cCity}, {cState} {cZip}</Text>
                    <Text style={textStyles["Default Text"]}> <Text style={textStyles["Bold Underline Text"]}>Country:</Text> {' '}{cCountry}</Text>
                    <Text style={textStyles["Default Text"]}> <Text style={textStyles["Bold Underline Text"]}>Email:</Text> {' '}{cEmail}</Text>
                    <Text style={textStyles["Default Text"]}> <Text style={textStyles["Bold Underline Text"]}>Phone:</Text> {' '}{cPhone}</Text>
                </View>
                <View style={otherStyles['Right Side']}>
                    <Text style={textStyles.Header}>Ship To</Text>
                    <Text style={textStyles["Default Text"]}> <Text style={textStyles["Bold Underline Text"]}>Company Name:</Text>  {' '}{shipping_content.ship_to_company}</Text>
                    <Text style={textStyles["Default Text"]}> <Text style={textStyles["Bold Underline Text"]}>Street Address:</Text> {' '}{shipping_content.ship_to_address_1}</Text>
                    <Text style={textStyles["Default Text"]}> <Text style={textStyles["Bold Underline Text"]}>City, State, Zip:</Text> {' '}{shipping_content.ship_to_city}, {shipping_content.ship_to_state} {shipping_content.ship_to_zip}</Text>
                    <Text style={textStyles["Default Text"]}> <Text style={textStyles["Bold Underline Text"]}>Country:</Text> {' '}{shipping_content.ship_to_country}</Text>
                </View>
            </View>

            {content && content.map((item, index) => {
                if (item.type === "string") {
                    if (item.style === textStyles.Su) {
                        return <Subtitle key={index}>{item.data}</Subtitle>
                    } else if (item.style === "Normal (Left) Text") { // HR - this is meant to catch styling for older quote versions, now is called "Default Text"
                        return <Text key={index} style={[textStyles["Default Text"], styles.contentMargin]}>{item.data}</Text>
                    } else {
                        return <Text key={index} style={[textStyles[item.style], styles.contentMargin]}>{item.data}</Text>
                    }
                } else if (item.type === "table") {
                    var tableKey = item.data.split(" ")[1]
                    return (<>
                        <Table data={item.tableData} header={header} type={type} discounts_enabled={discounts_enabled} key={index} tableTotal={total.data[tableKey]} grandTotal={total.grand_total}
                            totalType={total.total_calculation} isLastTable={tableKey === lastTable} currency={currency} show_description={show_description}/>
                    </>)
                } else if (item.type === "space") {
                    var spaceString = SpaceText(item.style)
                    return (<>
                        <Text key={index}>{spaceString}</Text>
                    </>)
                }
                // else if (item.type === "image") {
                //     return <Image key={index} style={otherStyles.Image} src="https://t3.ftcdn.net/jpg/02/48/42/64/240_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg" />
                // } else if (item.type === "table") {
                //     // return <Table key={index} data={products} header={dataHeader} />
                // }
            })}
        </View>
    )
}

export default QuoteHeader;