import { toast } from 'react-toastify'
import * as actions from "./accountSlice"
import axios from "axios"
import getApiUri from '../../helper/getApiUri'

const apiUrl = getApiUri() + "/accounts"


export const createAccount = async (payload, dispatch) => {
    dispatch(actions.createAccountStart())
    try {
        const { data } = await axios.post(apiUrl, payload)
        dispatch(actions.createAccountSuccess(data.data.account_id))
        toast.success(data.message);
        return true
    } catch (error) {
        dispatch(actions.createAccountFailure())
        console.log(error)
        toast.error(error.response.data.message);
        return false
    }
}


export const createPortal = async (payload) => {
    try {
        const { data } = await axios.post(apiUrl + "/portal", payload)
        return data.data.url
    } catch (error) {
        console.log(error)
        return false
    }
}

export const verifyGoogle = async (payload, dispatch) => {
    try {
        const { data } = await axios.get(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${payload.token}`)
        return data.email;
    } catch (error) {
        if (
            error.response &&
            error.response.status >= 400 &&
            error.response.status < 500
        ) {
            toast.error(error.response.data.message);
        } else {
            console.log(error);
            toast.error("Something went wrong!");
        }
        return false;
    }
};

export const updateAccount = async (payload, dispatch) => {
    dispatch(actions.updateAccountStart())
    try {
        const url = apiUrl + '/' + payload.id
        const { data } = await axios.put(url, payload.data)
        dispatch(actions.updateAccountSuccess(data.data))
        toast.success(data.message)
        return true
    } catch (error) {
        toast.error("Something went wrong!");
        dispatch(actions.updateAccountFailure())
        return false
    }
}

export const getAccountById = async (payload, dispatch) => {
    dispatch(actions.getAccountStart())
    try {
        const url = apiUrl + '/' + payload
        const {data} = await axios.get(url, payload)
        dispatch(actions.getAccountSuccess(data.data))
        toast.success(data.message)
        return true
    } catch (error) {
        console.log(error)
        dispatch(actions.getAccountFailure())
        toast.error(error.response.data.message)
        return false
    }
}