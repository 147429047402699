import { StyleSheet } from "@react-pdf/renderer";

//  HR - programmer-defined styles for PDF text items
const textStyles = StyleSheet.create({
  "Default Text": {
    fontSize: 12,
    textAlign: "left",
    marginBottom: 2.5,
    fontFamily: "Times-Roman",
    marginHorizontal: 5,
    lineHeight: 1.5
  },
  "Right Text": {
    fontSize: 12,
    textAlign: "right",
    marginBottom: 2.5,
    fontFamily: "Times-Roman",
    marginHorizontal: 5,
    lineHeight: 1.5
  },
  "Center Text": {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 2.5,
    fontFamily: "Times-Roman",
    lineHeight: 1.5
  },
  "Bold Text": {
    fontSize: 12,
    fontFamily: "Times-Bold",
    marginBottom: 2.5,
    marginHorizontal: 5,
    lineHeight: 1.5
  },
  "Underline Text": {
    fontSize: 12,
    textDecoration: "underline",
    marginBottom: 2.5,
    marginHorizontal: 5,
    lineHeight: 1.5
  },
  "Italic Text": {
    fontSize: 12,
    fontFamily: "Times-Italic",
    marginBottom: 2.5,
    marginHorizontal: 5,
    lineHeight: 1.5
  },
  "Bold Underline Text": {
    fontSize: 12,
    textDecoration: "underline",
    fontFamily: "Times-Bold",
    marginBottom: 2.5,
    marginHorizontal: 5,
    lineHeight: 1.5
  },
  Title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  Subtitle: {
    fontSize: 16,
    marginVertical: 5,
    fontFamily: "Oswald",
    textAlign: "center",
  },
  Header: {
    fontSize: 16,
    marginVertical: 5,
    fontFamily: "Times-Bold",
  },
});

const otherStyles = StyleSheet.create({
  Image: {
    marginTop: 2,
    alignSelf: "center",
    width: 'auto',
    height: 50,
  },
  "Page Number": {
    position: "absolute",
    fontFamily: "Times-Roman",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 30,
    textAlign: "right",
    color: "grey",
  },
  "Footer Text 1": {
    position: "absolute",
    fontFamily: "Times-Roman",
    fontSize: 12,
    bottom: 60,
    left: 0,
    right: 0,
    textAlign: "center",
  },
  "Footer Text 2": {
    position: "absolute",
    fontFamily: "Times-Roman",
    fontSize: 12,
    bottom: 45,
    left: 0,
    right: 0,
    textAlign: "center",
  },
  "Footer Text 3": {
    position: "absolute",
    fontFamily: "Times-Roman",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
  },
  Body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  Header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  Container: {
    marginVertical: 10,
  },
  Row: {
    marginTop: 10,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  "Header Row": {
    marginTop: 10,
    display: "flex",
    alignItems: 'center'
  },
  "Left Section": {
    marginVertical: 10,
    alignItems: 'left',
  },
  "Left Quote": {
    width: '50%',
    alignItems: 'flex-stretch',
  },
  "Right Quote": {
    width: '50%',
    alignItems: 'flex-stretch',
  },
  "Left Side": {
    width: '50%',
    alignItems: 'flex-start',
    marginLeft: 35,
    marginRight: 30,
    marginBottom: 10,
  },
  "Right Side": {
    width: '50%',
    alignItems: 'flex-start',
    marginLeft: 30,
    marginRight: 35,
    marginBottom: 10,
  },
  "Note PO": {
    alignItems: 'flex-start',
    marginLeft: 35,
    marginRight: 35,
    marginTop: 10,
  },
  "Grand Total": {
    fontSize: 12,
    textAlign: "right",
    padding: 5,
    paddingHorizontal: 12.5,
    border: '1px solid black',
    fontFamily: "Times-Roman",
  },
  "Watermark Text": {
    transform: 'rotate(-45deg)',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.2)',
    fontSize: 200,
    left: 75,
    position: 'absolute',
    top: 200,
  }
})

const termsStyles = StyleSheet.create({
  "Terms Container": {
    marginVertical: 10,
    marginHorizontal: 15,
  },
  "Terms Section": {
    marginVertical: 7.5,
    alignItems: 'left',
  },
  "Terms Header Text": {
    fontSize: 12,
    textDecoration: "underline",
    fontFamily: "Times-Bold",
    marginVertical: 10,
    marginHorizontal: 5,
  },
  "Terms Footer": {
    position: "absolute",
    fontFamily: "Times-Roman",
    fontSize: 12,
    bottom: 90,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  "Terms Footer Text": {
    fontSize: 12,
    marginVertical: 2.5,
    fontFamily: "Times-Roman",
    marginHorizontal: 5,
    lineHeight: 1
  },
  "Terms Text": {
    fontSize: 12,
    textAlign: "left",
    marginBottom: 10,
    fontFamily: "Times-Roman",
    marginHorizontal: 5,
    lineHeight: 1.5
  },
  "Terms Right Text": {
    fontSize: 12,
    textAlign: "flex-start",
    marginBottom: 10,
    fontFamily: "Times-Roman",
    marginHorizontal: 5,
    lineHeight: 1.5
  },
  "Terms 2 Header Text": {
    fontSize: 7,
    textAlign: "left",
    fontFamily: "Times-Bold",
    textDecoration: "underline",
    marginHorizontal: 5,
    lineHeight: 1.5,
    marginTop: 5
  },
  "Terms 2 Text": {
    fontSize: 7,
    textAlign: "left",
    fontFamily: "Times-Roman",
    marginHorizontal: 5,
    lineHeight: 1.5
  },
})


export { textStyles, otherStyles, termsStyles };
