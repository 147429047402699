import { createSlice } from "@reduxjs/toolkit";
import { customAlphabet } from 'nanoid';
import * as calc from "../../helper/productPriceFunctions"
import { nanoid } from "nanoid";

export const createDocSlice = createSlice({
    name: "createDoc",
    initialState: {
        contact: {},
        contact_index: {},
        products: [],
        tables: {},
        product_indicies: {},
        content: [],
        terms_content: {},
        shipping_content: {},
        total: {},
        is_complete: false,
        shipping_content_change: false,
        shipping_content_incomplete: true,
        doc_type: null,
        global_discount_options: {},
        discounts_enabled: false,
        discount_mode: null,
        related_quote: null,
        active: null,
        edit_mode: null,
        doc_number: 0,
        version: 0,
        is_void: null,
        created_by: null,
        recent_mode_change: false,
        createDocProgress: false,
        editDocProgress: false,
        date: null,
        show_description: true,
    },
    reducers: {
        // HR - process of creating/saving document data but does NOT save to database
        beginSuccess: (state, action) => {
            state.date = action.payload.date
            state.doc_number = action.payload.doc_number
            state.version = action.payload.version
            // state.discount_mode = action.payload.discount_mode
            state.doc_type = action.payload.doc_type
            state.createDocProgress = true
            state.is_void = false
        },
        initializeSuccess: (state, action) => {
            state.contact = action.payload.contactRows
            state.contact_index = action.payload.contactIndex
        },
        itemizeSuccess: (state, action) => {
            // HR - filter out products that have already been selected (as not to reset quantity, price, etc.)
            const existingIds = state.products.map((product) => { return product._id })
            const newRows = action.payload.productRows.filter((row) => { return !existingIds.includes(row._id) })
            var updatedRows = state.products.concat(newRows)

            // HR - filter out products from state that are no longer selected
            const newIds = action.payload.productRows.map((product) => { return product._id })
            updatedRows = updatedRows.filter((row) => newIds.includes(row._id))

            // HR - add new rows to product state instead of replacing all with selected rows (maintains any changes to quantity, price, etc.)
            state.products = updatedRows
            state.product_indicies = action.payload.productIndicies

            // HR - the block below is used to caculated product and grand totals using quantity, discounts, and price OR calculated_price
            state.products = state.products.map((productRow) => {
                var returnProduct = productRow
                if (state.discounts_enabled) {
                    returnProduct.discount_options = state.global_discount_options
                    returnProduct.calculated_price = calc.CalculateMyPrice(returnProduct)
                    returnProduct.total = calc.QuoteDiscountedCalculator(returnProduct)
                } else {
                    if (state.doc_type === "PO") {
                        returnProduct.total = calc.POCalculcator(returnProduct)
                    } else {
                        // HR - total calculation for quotes & invoices
                        returnProduct.total = calc.QuoteCalculator(returnProduct)
                    }
                }

                return returnProduct
            })
            var myTotal = calc.TableTotalCalculator(state.products, state.tables)
            state.total.data = myTotal.itemized
            state.total.grand_total = myTotal.grand
        },
        updateDiscountsEnabled: (state, action) => {
            state.discounts_enabled = action.payload.value
            state.global_discount_options = action.payload.globalDiscountOptions
            state.recent_mode_change = true
        },
        updateShowDescription: (state, action) => {
            state.show_description = action.payload
        },
        resetRecentModeChange: (state) => {
            state.recent_mode_change = false
        },
        reorderSuccess: (state, action) => {
            state.tables = action.payload
            var myTotal = calc.TableTotalCalculator(state.products, state.tables)
            state.total.data = myTotal.itemized
            state.total.grand_total = myTotal.grand
        },
        updateItemize: (state, action) => {
            // HR - when user updates product row, product total, discount, calculated_price, and grand total are recalculated
            state.products = state.products.map((product) => {
                if (product._id === action.payload._id) {
                    var returnProduct = { ...action.payload }
                    if (state.discounts_enabled) {
                        // HR - not exactly sure why there was an error changing price as a "read-only" property, but this seemed to fix it
                        returnProduct = { ...returnProduct, calculated_price: parseFloat(product.list_price).toFixed(2) }
                        // HR - if discount exists AND calculated_price has changed AND discount mode NOT recently changed, update new discount using price
                        if (product.calculated_price && (returnProduct.calculated_price !== product.calculated_price) && !state.recent_mode_change) {
                            returnProduct.discount = calc.DiscountCalculator(returnProduct)
                        }
                        returnProduct.calculated_price = calc.CalculateMyPrice(returnProduct)
                        returnProduct.total = calc.QuoteDiscountedCalculator(returnProduct)
                    } else {
                        if (state.doc_type === "PO") {
                            returnProduct.total = calc.POCalculcator(returnProduct)
                        } else {
                            // HR - total calculation for quotes & invoices
                            returnProduct.total = calc.QuoteCalculator(returnProduct)
                        }
                    }
                    return returnProduct
                } else {
                    return product
                }
            })
            var myTotal = calc.TableTotalCalculator(state.products, state.tables)
            state.total.data = myTotal.itemized
            state.total.grand_total = myTotal.grand
        },
        deleteItemize: (state, action) => {
            state.products = state.products.filter((product) => {
                if (product._id !== action.payload.id) {
                    return product
                }
            })
            var myTotal = calc.TableTotalCalculator(state.products, state.tables)
            state.total.data = myTotal.itemized
            state.total.grand_total = myTotal.grand
            delete state.product_indicies[action.payload.index]
        },
        toggleTotalCaclulation: (state, action) => {
            // HR - update total calculation method based on switch value
            if (action.payload) {
                state.total.total_calculation = "itemized"
            } else {
                state.total.total_calculation = "grand"
            }
            var myTotal = calc.TableTotalCalculator(state.products, state.tables)
            state.total.data = myTotal.itemized
            state.total.grand_total = myTotal.grand
        },
        customizeSuccess: (state, action) => {
            state.content = action.payload.items
        },
        organizeSuccess: (state, action) => {
            // HR - updates the existing shipping state by adding data for the fields below (specific to POs)
            state.shipping_content.shipping_via = action.payload.shipping_via
            state.shipping_content.required_by = action.payload.required_by
            state.shipping_content.project_name = action.payload.project_name
            state.shipping_content.category = action.payload.category
            state.shipping_content.sub_category = action.payload.sub_category
            state.shipping_content.notes = action.payload.notes
        },
        setTermsSuccess: (state, action) => {
            state.terms_content = action.payload
        },
        finalizeSuccess: (state, action) => {
            state.pages = action.payload
        },
        initializeSave: (state, action) => {
            var shipInfo = action.payload.shipping
            // HR - set initial shipping info (company, address, attention, and mark_package)
            if (shipInfo.ship_to_company !== "" && shipInfo.ship_to_address_1 !== "" && shipInfo.ship_to_city !== "" &&
                shipInfo.ship_to_state !== "" && shipInfo.ship_to_country !== "" && shipInfo.ship_to_zip !== "") {
                state.shipping_content_incomplete = false
                state.shipping_content = shipInfo
            } else {
                state.shipping_content_incomplete = true
            }
            // HR - if user manually set doc_number and date, reset redux state to those values
            if (action.payload.mode === "manual") {
                state.doc_number = action.payload.manInfo.docNumber
                state.date = action.payload.manInfo.date
            } else {  // HR - reset doc_number and date in case user switched back and forth
                state.doc_number = action.payload.docNum
                const now = new Date()
                const newDate = now.toISOString().split('T')[0]
                state.date = newDate
            }

        },

        shippingContentChange: (state) => {
            state.shipping_content_change = true
        },
        shippingContentSave: (state) => {
            state.shipping_content_change = false
        },

        fullReset: (state) => {
            state.active = null
            state.contact = {}
            state.is_complete = false
            state.contact_index = {}
            state.products = []
            state.tables = {}
            state.product_indicies = {}
            state.content = []
            state.terms_content = {}
            state.shipping_content = {}
            state.shipping_content_incomplete = true
            state.total = {}
            state.date = null
            state.edit_mode = null
            state.related_quote = null
            state.discounts_enabled = false
            state.global_discount_options = {}
            state.discount_mode = null
            state.doc_number = 0
            state.shipping_content_change = false
            state.createDocProgress = false
            state.show_description = true
            state.is_void = null
        },

        partialReset: (state) => {
            state.active = null
            state.contact = {}
            state.is_complete = false
            state.contact_index = {}
            state.products = []
            state.tables = {}
            state.product_indicies = {}
            state.content = []
            state.terms_content = {}
            state.shipping_content = {}
            state.shipping_content_incomplete = true
            state.total = {}
            state.edit_mode = null
            state.related_quote = null
            state.discounts_enabled = false
            state.global_discount_options = {}
            state.discount_mode = null
            state.shipping_content_change = false
            state.createDocProgress = false
            state.show_description = true
            state.is_void = null
        },

        editStart: (state, action) => {
            state.active = action.payload.active
            state.is_complete = action.payload.is_complete
            state.doc_type = action.payload.type
            state.contact = action.payload.contact
            state.contact_index = action.payload.contact_index
            state.products = action.payload.products
            state.show_description = action.payload.showDescription
            // HR - if editing draft without shipping_content, set flag to mark as incomplete
            if (action.payload.shipping_content) {
                state.shipping_content_incomplete = false
            } else {
                state.shipping_content_incomplete = true
            }
            // HR - if document does NOT have tables object but DOES have products, place all products in table A
            if (action.payload.tables) {
                state.tables = action.payload.tables
                state.content = action.payload.content
            } else if (action.payload.products.length > 0) {
                var tempTable = { A: [] }
                action.payload.products.map(product => {
                    tempTable.A.push(product.name + ":::(" + product.product_id + ")")
                })
                state.tables = tempTable
                // HR - create content item for table, meant for old documents
                var tempContent = [...action.payload.content]
                tempContent.push({
                    data: "Table A",
                    tableData: action.payload.products,
                    id: nanoid(21),
                    style: "table",
                    type: "table"
                })
                state.content = tempContent
            }
            if (action.payload.product_indicies) {
                state.product_indicies = action.payload.product_indicies
            } else {
                state.product_indicies = {}
            }
            if (action.payload.terms_content) {
                state.terms_content = action.payload.terms_content
            } else {
                state.terms_content = {}
            }
            if (action.payload.total) {
                state.total = action.payload.total
            } else {
                state.total = {}
            }
            state.discounts_enabled = action.payload.discounts_enabled
            state.global_discount_options = action.payload.global_discount_options
            state.discount_mode = action.payload.discount_mode
            // HR - if shipping_content object is empty (old quote), reset to be semi-empty object to fix 'shipping_content' required bug
            if (!action.payload.shipping_content) {
                state.shipping_content = {
                    shipping_via: "",
                    required_by: "",
                    project_name: "",
                    category: "",
                    sub_category: "",
                    notes: "",
                }
            } else {
                state.shipping_content = action.payload.shipping_content
            }
            const editDate = new Date().toISOString().split('T')[0]
            state.date = editDate
            state.version = action.payload.version
            state.doc_number = action.payload.doc_number
            state.created_by = action.payload.created_by
            state.editDocProgress = true
            state.is_void = action.payload.is_void
        },

        setEditMode: (state, action) => {
            state.edit_mode = action.payload.mode
            // HR - if making an invoice form a quote, set related_quote to current doc_number
            if (action.payload.mode === 'invoice') {
                state.related_quote = state.doc_number
            }
            // HR - if editing as new document or new invoice, generate new doc_number and date
            if (action.payload.mode === 'new' || action.payload.mode === 'invoice') {
                const now = new Date()
                const newDate = now.toISOString().split('T')[0]
                const day = now.getUTCDate()
                const nanoid = customAlphabet('1234567890', 5)
                state.doc_number = `${action.payload.account_initial}${day}${nanoid()}${action.payload.user_initials}`
                state.date = newDate
            }
        },

    },
})

export const {
    beginSuccess,
    initializeSuccess,
    itemizeSuccess,
    updateItemize,
    updateDiscountsEnabled,
    updateShowDescription,
    resetRecentModeChange,
    reorderSuccess,
    deleteItemize,
    toggleTotalCaclulation,
    customizeSuccess,
    organizeSuccess,
    setTermsSuccess,
    finalizeSuccess,
    initializeSave,
    shippingContentChange,
    shippingContentSave,
    fullReset,
    partialReset,
    editStart,
    setEditMode
} = createDocSlice.actions

export default createDocSlice.reducer