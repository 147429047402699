import {
  Text,
  Image,
  View
} from '@react-pdf/renderer';
import quoteHeader from './PDF-Table/quoteTableHeader'
import poHeader from './PDF-Table/poTableHeader'
import quoteDiscountHeader from './PDF-Table/quoteDiscountHeader'
import Table from "./PDF-Table/Table";
import { textStyles, otherStyles, termsStyles } from "../../styles/PDFStyles"
import POHeader from './POHeader';
import QuoteHeader from './QuoteHeader';
import TermsPage1 from './TermsPage1';
import TermsPage2 from './TermsPage2';
import tablesHelperPDF from '../../helper/tableHelperPDF';

const Subtitle = ({ children, ...props }) => (
  <Text style={textStyles["Subtitle"]} {...props}>
    {children}
  </Text>
);

// HR - PDFDoc details the actual content and styling of the PDF
export default function PDFDoc(props) {
  const { type, doc_number, user_name, account_id, account_name, date, aAddress_1, aCity, aState, aZip, aPhone, aEmail, aFax, cName, cAddress_1, cCity,
    cState, cCountry, cZip, cPhone, cEmail, products, tables, content, total, shipping_content, terms_content, discounts_enabled, logo, default_valid_for,
    default_terms_text, currency, show_description, is_void } = props

  const properTables = tablesHelperPDF(tables, products)
  const properTableKeys = Object.keys(properTables)
  const lastTable = properTableKeys[properTableKeys.length - 1]


  function getHeader() {
    if (type === "PO") {
      return poHeader
    } else if (discounts_enabled) {
      return quoteDiscountHeader
    } else {
      return quoteHeader
    }
  }

  const myHeader = getHeader()

  return (
    <>
      {is_void && <Text render={({ pageNumber, totalPages }) => `Void`} fixed style={otherStyles['Watermark Text']} />}

      {/* HR - General Heading information */}
      {/* HR - must get image url with no cache option, otherwise logo will NOT render */}
      <Image
        style={{ ...otherStyles.Image, marginBottom: 3 }}
        src={{ uri: logo, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
        fixed
      />
      {/* HR - variable title based on document type */}
      {type === "PO" && <Subtitle>Purchase Order</Subtitle>}
      {type === "Quote" && <Subtitle>Quotation</Subtitle>}
      {type === "Invoice" && <Subtitle>Invoice</Subtitle>}

      {type === "PO" ?
        <>
          <POHeader type={type} user_name={user_name} date={date} doc_number={doc_number} cName={cName} cAddress_1={cAddress_1} cCity={cCity} cState={cState} cZip={cZip}
            cCountry={cCountry} cPhone={cPhone} cEmail={cEmail} shipping_content={shipping_content} content={content} header={myHeader} total={total} lastTable={lastTable}
            currency={currency} show_description={show_description} />
        </>
        :
        <QuoteHeader type={type} user_name={user_name} date={date} doc_number={doc_number} cName={cName} cAddress_1={cAddress_1} cCity={cCity} cState={cState} cZip={cZip}
          cCountry={cCountry} cPhone={cPhone} cEmail={cEmail} shipping_content={shipping_content} content={content} header={myHeader} discounts_enabled={discounts_enabled}
          total={total} lastTable={lastTable} currency={currency} show_description={show_description} />
      }

      {/* HR - if quote, show terms page 1 */}
      {(type === "Quote" || type === "Invoice") && <TermsPage1 user_name={user_name} account_name={account_name} terms_content={terms_content} default_terms_text={default_terms_text} />}

      {/* HR - if quote, show terms page 2 */}
      {/* {((type === "Quote" || type === "Invoice")) && <TermsPage2 />} */}

      <Text
        style={otherStyles["Footer Text 1"]}
        render={() => {
          return (`${aAddress_1}, ${aCity}, ${aState} ${aZip}`)
        }}
        fixed
      />
      <Text
        style={otherStyles["Footer Text 2"]}
        render={() => {
          return (`(Phone): ${aPhone} (Fax): ${aFax}`)
        }}
        fixed
      />
      <Text
        style={otherStyles["Footer Text 3"]}
        render={() => {
          return (`(Email): ${aEmail}`)
        }}
        fixed
      />
      {/* HR - this either renders page x/y or a notice if on the last page (the terms page) */}
      <Text
        style={otherStyles["Page Number"]}
        render={({ pageNumber, totalPages }) => (
          (pageNumber !== totalPages || pageNumber !== totalPages - 1) ?
            `${pageNumber} / ${totalPages}`
            : null
        )}
        fixed
      />
      <Text
        style={termsStyles["Terms Footer"]}
        render={({ pageNumber, totalPages }) => (
          (pageNumber === totalPages) && type === "Quote" ?
            `*Quotation is valid for ${default_valid_for} days*`
            : null
        )}
        fixed
      />
    </>
  )
}