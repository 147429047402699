import axios from "axios";
import { toast } from "react-toastify";
import * as actions from "./authSlice";
import { googleLogout } from "@react-oauth/google";
import { getAccountSuccess } from "../Account/accountSlice";
import getApiUri from '../../helper/getApiUri'

const apiUrl = getApiUri() + "/login"

export const login = async (payload, dispatch) => {
	dispatch(actions.loginStart());
	try {
		const { data } = await axios.get(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${payload}`)
		const nextPayload = { email: data.email, image: data.picture, name: data.name }
		verifyLogin(nextPayload, dispatch)
		return true;
	} catch (error) {
		dispatch(actions.loginFailure());
		if (
			error.response &&
			error.response.status >= 400 &&
			error.response.status < 500
		) {
			toast.error(error.response.data.message);
		} else {
			console.log(error);
			toast.error("Something went wrong!");
		}
		return false;
	}
};

export const verifyLogin = async (payload, dispatch) => {
	try {
		const { data } = await axios.post(apiUrl, { email: payload.email });
		dispatch(actions.loginSuccess({ user_id: data.data.user_id, email: payload.email, user_name: payload.name, user_role: data.data.user_role, display_name: data.data.display_name }));
		dispatch(getAccountSuccess({
			account_id: data.data.account_id, account_name: data.data.account_name, terms: data.data.terms, phone: data.data.phone, email: data.data.email, 
			fax: data.data.fax, address_1: data.data.address_1, address_2: data.data.address_2, city: data.data.city, state: data.data.state, 
			country: data.data.country, zip: data.data.zip, currency: data.data.currency, product_number: data.data.product_number, document_number: data.data.document_number,
			logo: data.data.logo, default_fob: data.data.default_fob, default_valid_for: data.data.default_valid_for, default_terms_text: data.data.default_terms_text, subscription: data.data.subscription
		}))
		toast.success(data.message);
		return true;
	} catch (error) {
		dispatch(actions.loginFailure());
		if (
			error.response &&
			error.response.status >= 400 &&
			error.response.status < 500
		) {
			toast.error(error.response.data.message);
		} else {
			console.log(error);
			toast.error("Something went wrong!");
		}
		return false;
	}
};

export const logout = async (dispatch) => {
	try {
		googleLogout()
		dispatch(actions.logout());
		return true;
	} catch (error) {
		console.log(error)
		return false;
	}
};

export const reportBug = async (formData, dispatch) => {
	try {
		const { data } = await axios.post(apiUrl + "/reportbug", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
		toast.success(data.message);
		return true;
	} catch (error) {
		// if (
		// 	error.response &&
		// 	error.response.status >= 400 &&
		// 	error.response.status < 500
		// ) {
		// 	toast.error(error.response.data.message);
		// } else {
		// 	console.log(error);
			toast.error("Something went wrong!");
		// }
		return false;
	}
};

export const contactUs = async (payload, dispatch) => {
	try {
		const { data } = await axios.post(apiUrl + "/contactus", payload)
		toast.success(data.message);
		return true;
	} catch (error) {
		if (
			error.response &&
			error.response.status >= 400 &&
			error.response.status < 500
		) {
			toast.error(error.response.data.message);
		} else {
			console.log(error);
			toast.error("Something went wrong!");
		}
		return false;
	}
};