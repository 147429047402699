import { Card, CardBody, Image, Text, Flex, useColorModeValue, Container } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import companyLogo2 from "../images/default_logo.png"
import { useState, useEffect } from 'react'
import { getProducts } from '../redux/Product/productCalls'
import { getContacts } from '../redux/Contact/contactCalls'
import { getResources } from '../redux/Resource/resourceCalls'
import { getDocuments, updateAllDocuments } from '../redux/Document/documentCalls'
import { getPaymentTerms } from '../redux/PaymentTerm/paymentTermCalls'

export default function Home() {
    const { user_name } = useSelector((state) => state.auth)
    const { account_logo } = useSelector((state) => state.account)
    const { account_id } = useSelector((state) => state.account)
    const { resources } = useSelector((state) => state.resources)
    const [currentLogo, setCurrentLogo] = useState(companyLogo2)
    // HR - first name is split off to fill out welcome string
    const firstName = user_name.split(" ")[0]
    const dispatch = useDispatch()

    useEffect(() => {
        getResources(account_id, dispatch)
        getContacts(account_id, dispatch)
        getProducts(account_id, dispatch)
        getDocuments(account_id, dispatch)
        getPaymentTerms(account_id, dispatch)
    }, [])

    // HR - if an account logo is assigned, get the resource_url from the resources redux state to render the correct image on screen
    useEffect(() => {
        if (account_logo && resources.length > 0) {
            var logoResource = resources.find((resource) => {
                return resource._id === account_logo
            })
            if (logoResource && logoResource.resource_url) {
                setCurrentLogo(logoResource.resource_url)
            }else {
                setCurrentLogo(companyLogo2)
            }
        }
    }, [account_logo, resources])

    return (
        <Flex
            minH={"100vh"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
        >
            <Container style={{ marginBottom: '20rem', alignItems: "center", justifyContent: "center", display: "flex" }}>
                <Card>
                    <CardBody>
                        <Image
                            src={currentLogo}
                            alt='Green double couch with wooden legs'
                            borderRadius='lg'
                        />
                        <Container centerContent mt={'1.5rem'} mb={'1rem'}>
                            <Text fontSize="3xl" fontFamily="heading" fontWeight="light">Welcome {firstName}!</Text>
                        </Container>
                    </CardBody>
                </Card>
            </Container>
        </Flex>
    )
}