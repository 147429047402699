import {
    Flex, useColorModeValue, Container, HStack, Text, VStack, FormControl, FormLabel, Textarea, Input, Button, Box,
    Heading, InputGroup, InputLeftElement, Select, Stack
} from "@chakra-ui/react"
import { useState } from "react"
import {
    MdEmail,
    MdLocationOn,
    MdOutlineEmail
} from "react-icons/md"
import { BsPerson } from "react-icons/bs"
import handleInputChange from "../../helper/inputChange"
import { useDispatch } from "react-redux"
import { contactUs } from "../../redux/Auth/authCalls"
import Footer from "../../components/Footer"

export default function ContactUs() {
    const [contactData, setContactData] = useState({
        first_name: "",
        last_name: "",
        work_email: "",
        company_name: "",
        company_size: "",
        message: ""
    })
    const dispatch = useDispatch()

    async function handleSubmitContact(e) {
        e.preventDefault()
        contactUs(contactData, dispatch)
        setContactData({
            first_name: "",
            last_name: "",
            work_email: "",
            company_name: "",
            company_size: "",
            message: ""
        })
    }
    function inputChange(e) {
        handleInputChange(e.target, setContactData)
    }

    return (
        <>
            <Flex
                minH={"fit-content"}
                justify={"center"}
                bg={useColorModeValue("gray.50", "gray.800")}
                px={"0.5rem"}
            >
                <Stack
                    as={Box}
                    textAlign={'center'}
                    spacing={{ base: 8, md: 14 }}
                    py={[10, 26, 30, 32]}
                    w={'fit-content'}>
                    <Heading
                        fontWeight={600}
                        fontSize={{ base: '4xl', sm: '5xl', md: '6xl' }}
                        lineHeight={'110%'}>
                        Contact
                        <Text as={'span'} color={'blue.400'} ml={'10px'} whiteSpace={'nowrap'}>
                            RapidToolz
                        </Text>
                    </Heading>
                    <Text color={'gray.500'} fontSize={'large'}>
                        Have any questions? Fill out this form so we can have someone reach out to you via email!
                    </Text>

                    <Box p={4}>
                        <Container centerContent>
                            <Box borderRadius="lg">
                                <Box m={8}>
                                    <form onSubmit={handleSubmitContact}>
                                        <VStack spacing={8}>
                                            <HStack>
                                                <FormControl isRequired>
                                                    <FormLabel>First Name</FormLabel>
                                                    <InputGroup borderColor="#E0E1E7">
                                                        <InputLeftElement pointerEvents="none">
                                                            <BsPerson color="gray.800" />
                                                        </InputLeftElement>
                                                        <Input type="text" size="md" name="first_name" value={contactData.first_name} onChange={inputChange} placeholder="Jane" />
                                                    </InputGroup>
                                                </FormControl>
                                                <FormControl isRequired>
                                                    <FormLabel>Last Name</FormLabel>
                                                    <InputGroup borderColor="#E0E1E7">
                                                        <InputLeftElement pointerEvents="none">
                                                            <BsPerson color="gray.800" />
                                                        </InputLeftElement>
                                                        <Input type="text" size="md" name="last_name" value={contactData.last_name} onChange={inputChange} placeholder="Doe" />
                                                    </InputGroup>
                                                </FormControl>
                                            </HStack>
                                            <FormControl isRequired>
                                                <FormLabel>Work Email</FormLabel>
                                                <InputGroup borderColor="#E0E1E7">
                                                    <InputLeftElement pointerEvents="none">
                                                        <MdOutlineEmail color="gray.800" />
                                                    </InputLeftElement>
                                                    <Input type="text" size="md" name="work_email" value={contactData.work_email} onChange={inputChange} placeholder="janeDoe@gmail.com" />
                                                </InputGroup>
                                            </FormControl>
                                            <FormControl isRequired>
                                                <FormLabel>Company Name</FormLabel>
                                                <InputGroup borderColor="#E0E1E7">
                                                    <InputLeftElement pointerEvents="none">
                                                        <BsPerson color="gray.800" />
                                                    </InputLeftElement>
                                                    <Input type="text" size="md" name="company_name" value={contactData.company_name} onChange={inputChange} placeholder="Jane's Jewels" />
                                                </InputGroup>
                                            </FormControl>
                                            <FormControl isRequired>
                                                <FormLabel>Company Size</FormLabel>
                                                <Select placeholder='Select company size' name="company_size" value={contactData.company_size} onChange={inputChange} borderColor="#E0E1E7">
                                                    <option value='1'>1</option>
                                                    <option value='2_to_10'>2-10</option>
                                                    <option value='11_to_50'>11-50</option>
                                                    <option value='51_to_200'>51-200</option>
                                                    <option value='201_to_500'>201-500</option>
                                                    <option value='500_up'>500+</option>
                                                </Select>
                                            </FormControl>
                                            <FormControl >
                                                <FormLabel>Message</FormLabel>
                                                <Textarea
                                                    borderColor="gray.300"
                                                    _hover={{
                                                        borderRadius: "gray.300"
                                                    }}
                                                    placeholder="Anything you'd like to tell us?"
                                                    name="message"
                                                    value={contactData.message} onChange={inputChange}
                                                />
                                            </FormControl>
                                            <FormControl float="right">
                                                <Button
                                                    variant="solid"
                                                    bg="#0D74FF"
                                                    color="white"
                                                    _hover={{}}
                                                    type="submit"
                                                >
                                                    Send Message
                                                </Button>
                                            </FormControl>
                                        </VStack>
                                    </form>
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                </Stack>
            </Flex>
            <Footer />
        </>
    )
}