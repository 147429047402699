import { Heading, Text, ListItem, UnorderedList } from "@chakra-ui/react";

export default function SetupForm() {

    return (
        <>
            <Heading fontSize={"4xl"} textAlign={"center"}>Your account has been created! Please login to begin using RapidToolz</Heading>
            <Text fontSize={"2xl"} textAlign={"center"}>Once inside your new account, check out the settings page to complete the following steps: </Text>
            <UnorderedList fontSize={"xl"}>
                <ListItem>Invite Users</ListItem>
                <ListItem>Select Company Currency</ListItem>
                <ListItem>Upload Company Resources</ListItem>
                <ListItem>Define Payment Terms</ListItem>
                <ListItem>Choose Company Logo</ListItem>
                <ListItem>Upload Terms and Conditions</ListItem>
                <ListItem>Set Terms Defaults</ListItem>
                <ListItem>And more...</ListItem>
            </UnorderedList>
        </>
    )
}