import {
    Box,
    Stack,
    HStack,
    Heading,
    Text,
    VStack,
    useColorModeValue,
    List,
    ListItem,
    ListIcon,
    Button,
} from "@chakra-ui/react"
import { FaCheckCircle, FaStripe } from "react-icons/fa"

function PriceWrapper(props) {
    const { children } = props

    return (
        <Box
            mb={4}
            shadow="base"
            borderWidth="1px"
            alignSelf={{ base: "center", lg: "flex-start" }}
            borderColor={useColorModeValue("gray.200", "gray.500")}
            borderRadius={"xl"}
        >
            {children}
        </Box>
    )
}

export default function SubscriptionForm({ incrementStep, userEmail }) {
    // HR - this id our websocket connection to our backend, used for receiving stripe events on frontend

    const ws = new WebSocket(process.env.REACT_APP_WS_URL)

    // HR - this function redirects user to stripe checkout page, autofilling user email and changes based on which plan they select
    function handleSubscribe(frequency) {
        var planLink = process.env.REACT_APP_MONTHLY_PLAN_LINK
        if (frequency === "yearly") {
            planLink = process.env.REACT_APP_YEARLY_PLAN_LINK
        }
        var url = planLink + '?prefilled_email=' + userEmail
        var win = window.open(url, '_blank')
        win.focus()
    }

    // HR - user is moved to next page after subscription is successful
    ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === 'checkout_completed') {
            // Update frontend based on payment success data
            incrementStep()
        }
    };

    // HR - this page is just filler until we implement stripe checkout
    return (
        <Box py={12}>
            <VStack spacing={2} textAlign="center">
                <Heading as="h1" fontSize="3xl">
                    Choose Subscription
                </Heading>
            </VStack>
            <Stack
                direction={{ base: "column", md: "row" }}
                textAlign="center"
                justify="center"
                spacing={{ base: 4, lg: 10 }}
                py={10}
            >
                <PriceWrapper>
                    <Box py={4} px={12}>
                        <Text fontWeight="500" fontSize="2xl">
                            Monthly
                        </Text>
                        <HStack justifyContent="center">
                            <Text fontSize="3xl" fontWeight="600">
                                $
                            </Text>
                            <Text fontSize="5xl" fontWeight="900">
                                20
                            </Text>
                            <Text fontSize="3xl" color="gray.500">
                                /month
                            </Text>
                        </HStack>
                    </Box>
                    <VStack
                        bg={useColorModeValue("gray.50", "gray.700")}
                        py={4}
                        borderBottomRadius={"xl"}
                    >
                        <List spacing={3} textAlign="start" px={12}>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                Document generation
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                Product and contact books
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                Upload company information and resources
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                Discount and markup features
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                Stylize PDFs with multiple tables, text, and spaces
                            </ListItem>
                        </List>
                        <Box w="80%" pt={7}>
                            <Button size={"lg"} onClick={() => handleSubscribe('monthly')} rightIcon={<FaStripe fontSize={"3rem"} />}>Subscribe with </Button>
                        </Box>
                    </VStack>
                </PriceWrapper>

                <PriceWrapper>
                    <Box position="relative">
                        <Box
                            position="absolute"
                            top="-16px"
                            left="50%"
                            style={{ transform: "translate(-50%)" }}
                        >
                            <Text
                                textTransform="uppercase"
                                bg={useColorModeValue("blue.400", "red.700")}
                                px={3}
                                py={1}
                                color={useColorModeValue("gray.900", "gray.300")}
                                fontSize="sm"
                                fontWeight="600"
                                rounded="xl"
                            >
                                Best Deal
                            </Text>
                        </Box>
                        <Box py={4} px={12}>
                            <Text fontWeight="500" fontSize="2xl">
                                Yearly
                            </Text>
                            <HStack justifyContent="center">
                                <Text fontSize="3xl" fontWeight="600">
                                    $
                                </Text>
                                <Text fontSize="5xl" fontWeight="900">
                                    200
                                </Text>
                                <Text fontSize="3xl" color="gray.500">
                                    /year
                                </Text>
                            </HStack>
                        </Box>
                        <VStack
                            bg={useColorModeValue("gray.50", "gray.700")}
                            py={4}
                            borderBottomRadius={"xl"}
                        >
                            <List spacing={3} textAlign="start" px={12}>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Document generation
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Product and contact books
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Upload company information and resources
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Discount and markup features
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Stylize PDFs with multiple tables, text, and spaces
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Save money!!!
                                </ListItem>
                            </List>
                            <Box w="80%" pt={7}>
                                <Button size={"lg"} onClick={() => handleSubscribe('yearly')} colorScheme={'blue'} rightIcon={<FaStripe fontSize={"3rem"} />}>Subscribe with </Button>
                            </Box>
                        </VStack>
                    </Box>
                </PriceWrapper>
            </Stack>
        </Box>
    )
}
