import { Flex, useColorModeValue, Stack, Heading, Text, Box } from "@chakra-ui/react"
import Footer from "../../components/Footer"

export default function Pricing() {
    return (
        <>
            <Flex
                minH={"fit-content"}
                justify={"center"}
                bg={useColorModeValue("gray.50", "gray.800")}
                pt={"2rem"}
            >
                <Stack
                    as={Box}
                    textAlign={'center'}
                    spacing={{ base: 8, md: 14 }}
                    py={[10, 26, 30, 32]}
                    w={'70%'}>
                    <Heading
                        fontWeight={600}
                        fontSize={{ base: '4xl', sm: '5xl', md: '6xl' }}
                        lineHeight={'110%'}>
                        How much does
                        <Text as={'span'} color={'blue.400'} ml={'10px'} whiteSpace={'nowrap'}>
                            RapidToolz
                        </Text> Cost?
                    </Heading>
                    <Text color={'gray.500'} fontSize={'large'}>
                        RapidToolz is currently on its first release, and we are hard at work adding some additional features for our full version.
                        We want to ensure that our software works for you and your small business, so until our next major update, we welcome you to test out
                        RapidToolz for free!
                    </Text>
                    <Text color={'gray.500'} fontSize={'large'}>
                        As it currently stands, you do not need to provide us with any payment information. We will update this page with a detailed price breakdown
                        once we approach our final release date. If you sign up to test RapidToolz, we will also send you a courtesy email alerting you that the software
                        will transition into the paid version.
                    </Text>
                </Stack>
            </Flex>
            <Footer />
        </>
    )
}