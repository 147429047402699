import { Box, Button, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react"
import { ChevronDownIcon } from '@chakra-ui/icons'
import { NavLink, useNavigate } from 'react-router-dom'
import { IoBagAddOutline } from 'react-icons/io5'


export default function AddCell({ type, openModal }) {
    var typeText = type
    if (type === "products") {
        typeText = "Product"
    }
    const navigate = useNavigate()
    
    return (
        <Box flex={0}>
            {type === "draft_history" &&
                <Menu>
                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />} colorScheme="green">
                        Create New
                    </MenuButton>
                    <MenuList>
                        <NavLink to="/create/quote"><MenuItem>Quote</MenuItem></NavLink>
                        <NavLink to="/create/purchaseorder"><MenuItem>Purchase Order</MenuItem></NavLink>
                    </MenuList>
                </Menu>
            }
            {typeText === "po_history" &&
                <Box>
                    <Button variant={"solid"} colorScheme={"green"} onClick={() => { navigate("/create/purchaseorder") }} leftIcon={<IoBagAddOutline style={{ fontSize: "1.5rem" }} />}>Create New PO</Button>
                </Box>
            }
            {(typeText === "quote_history" || typeText === "invoice_history") &&
                <Box>
                    <Button variant={"solid"} colorScheme={"green"} onClick={() => { navigate("/create/quote") }} leftIcon={<IoBagAddOutline style={{ fontSize: "1.5rem" }} />}>Create New Quote</Button>
                </Box>
            }
            {(typeText === "Product" || typeText === "Contact") &&
                <Box>
                    <Button variant={"solid"} colorScheme={"green"} onClick={openModal} leftIcon={<IoBagAddOutline  />}>New {typeText}</Button>
                </Box>
            }
        </Box>
    )
}