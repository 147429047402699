import {
    Flex, useColorModeValue, Button, Heading, Image, Stack, Text, useBreakpointValue, Box, SimpleGrid, Icon, VStack, CardBody, Card,
    Container, HStack, Accordion, AccordionButton, AccordionItem, AccordionPanel
} from "@chakra-ui/react"
import ComputerPic from "../../images/computer_picture.jpg"
import ContactPic from "../../images/contact_pic.jpg"
import { FcCollaboration, FcServices, FcShop } from "react-icons/fc"
import { useNavigate } from "react-router-dom"
import { CheckIcon, ChevronDownIcon } from "@chakra-ui/icons"
import Footer from "../../components/Footer"

const Feature = ({ title, text, icon }) => {
    return (
        <Stack >
            <Flex
                align={"center"}
                justify={"center"}
                color={"white"}
                rounded={"full"}
                bg={"gray.100"}
                m={1}
            >
                <Card p={3}>
                    <CardBody>
                        {icon}
                        <Text fontWeight={600}>{title}</Text>
                        <Text color={"gray.600"}>{text}</Text>
                    </CardBody>
                </Card>
            </Flex>
        </Stack>
    )
}

const features = [
    {
        id: 1,
        title: "Store your contact and product information",
        text: "Keep your customers, vendors, and products all in one place"
    },
    {
        id: 2,
        title: "Automatically merge your terms and conditions",
        text: "Upload your terms file and it will be added to all quotes and invoices"
    },
    {
        id: 3,
        title: "Easily apply discount and markup to quotes",
        text: "Pick from list price or cost, table or row manipulation"
    },
    {
        id: 4,
        title: "Instantly convert your quotations into invoices",
        text: "Save yourself the time and effort, we'll convert it for you"
    },
    {
        id: 5,
        title: "Upload and store files as company resources",
        text: "Store your logo, terms file, and whatever else you need on hand to create documents"
    },
    {
        id: 6,
        title: "Style your documents with text, tables, and spaces",
        text: "Customize your layout by sorting products into multiple tables, and add stylize your text"
    },
]

export default function HomeSO() {
    const navigate = useNavigate()
    return (
        <>
            <Flex
                minH={"fit-content"}
                justify={"center"}
                bg={useColorModeValue("gray.50", "gray.800")}
                pt={"2rem"}
            >
                <VStack>
                    <Stack minH={"30vh"} direction={{ base: "column", md: "row" }}>
                        <Flex p={8} flex={1} align={"center"} justify={"center"}>
                            <Stack spacing={6} w={"full"} maxW={"lg"}>
                                <Heading fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}>
                                    <Text
                                        as={"span"}
                                        position={"relative"}
                                        _after={{
                                            content: "''",
                                            width: "full",
                                            height: useBreakpointValue({ base: "20%", md: "30%" }),
                                            position: "absolute",
                                            bottom: 1,
                                            left: 0,
                                            bg: "blue.400",
                                            zIndex: -1
                                        }}
                                    >
                                        Generate
                                    </Text>
                                    <br />{" "}
                                    <Text color={"blue.400"} as={"span"}>
                                        Business Documents
                                    </Text>{" "}
                                </Heading>
                                <Text fontSize={{ base: "md", lg: "lg" }} color={"gray.500"}>
                                    RapidToolz is built for smaller businesses looking to simplify their document generation process.
                                </Text>
                                <Stack direction={{ base: "column", md: "row" }} spacing={4}>
                                    <Button
                                        rounded={"full"}
                                        bg={"blue.400"}
                                        color={"white"}
                                        _hover={{
                                            bg: "blue.500"
                                        }}
                                        onClick={() => { navigate("/signup/company") }}
                                    >
                                        Get Started
                                    </Button>
                                    <Button rounded={"full"}>How It Works</Button>
                                </Stack>
                            </Stack>
                        </Flex>
                        <Flex flex={1}>
                            <Image
                                alt={"Login Image"}
                                objectFit={"contain"}
                                src={ComputerPic}
                            />
                        </Flex>
                    </Stack>
                    <Box p={4} mx={['2.5rem', '5rem', '2.5rem', '10rem', '15rem']} my={'5rem'}>
                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
                            <Feature
                                icon={<Icon as={FcCollaboration} w={10} h={10} />}
                                title={"User Friendly"}
                                text={
                                    "We won't overwhelm you with hundreds of features and options. We want the process to be as simple and easy-to-follow as possible!"
                                }
                            />
                            <Feature
                                icon={<Icon as={FcServices} w={10} h={10} />}
                                title={"Simple Setup"}
                                text={
                                    "This software is meant to save you time, and so we made sure you won't spend too long setting up your account. Go ahead and jump right in!"
                                }
                            />
                            <Feature
                                icon={<Icon as={FcShop} w={10} h={10} />}
                                title={"Small Business"}
                                text={
                                    "Our mission is to simplify the document generation process for smaller businesses. Our features were built with your needs in mind!"
                                }
                            />
                        </SimpleGrid>
                    </Box>
                    <Stack minH={"30vh"} direction={{ base: "column", md: "row" }}>
                        <Flex flex={1} >
                            <Image
                                alt={"Login Image"}
                                objectFit={"contain"}
                                src={ContactPic}
                            />
                        </Flex>
                        <Flex p={8} flex={1} align={"center"} justify={"center"}>
                            <Stack spacing={6} w={"full"} maxW={"lg"}>
                                <Heading fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}>
                                    <Text
                                        as={"span"}
                                        position={"relative"}
                                        _after={{
                                            content: "''",
                                            width: "full",
                                            height: useBreakpointValue({ base: "20%", md: "30%" }),
                                            position: "absolute",
                                            bottom: 1,
                                            left: 0,
                                            bg: "blue.400",
                                            zIndex: -1
                                        }}
                                    >
                                        Consolidate
                                    </Text>
                                    <br />{" "}
                                    <Text color={"blue.400"} as={"span"}>
                                        Your Information
                                    </Text>{" "}
                                </Heading>
                                <Text fontSize={{ base: "md", lg: "lg" }} color={"gray.500"}>
                                    We have included easy to read tables to store your company's contact and product books.
                                </Text>
                            </Stack>
                        </Flex>
                    </Stack>
                    <Box p={4} my={'5rem'}>
                        <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
                            <Heading fontSize={"3xl"}>What we have to offer</Heading>
                            <Text color={"gray.600"} fontSize={"xl"}>
                                Whether you need quotes, invoices, or purchase orders, we've got plenty of features to suite your business needs
                            </Text>
                        </Stack>

                        <Container maxW={"6xl"} mt={14}>
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
                                {features.map(feature => (
                                    <HStack key={feature.id} align={"top"}>
                                        <Box color={"green.400"} px={2}>
                                            <Icon as={CheckIcon} />
                                        </Box>
                                        <VStack align={"start"}>
                                            <Text fontWeight={600}>{feature.title}</Text>
                                            <Text color={"gray.600"}>{feature.text}</Text>
                                        </VStack>
                                    </HStack>
                                ))}
                            </SimpleGrid>
                        </Container>
                    </Box>
                    <Heading fontSize={"3xl"} mb={"3rem"}>Frequently Asked Questions</Heading>
                    <Accordion width="60%" rounded="lg" mb="5rem">
                        <AccordionItem>
                            <AccordionButton
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                p={4}
                                _expanded={{ bg: 'blue.400', color: 'white' }}
                            >
                                <Text fontSize="md">What is RapidToolz?</Text>
                                <ChevronDownIcon fontSize="24px" />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Text color="gray.600">
                                    RapidToolz is a document generation software designed to allow smaller businesses to migrate their quotes, purchase orders, and invoices all in one place.
                                    You can create products and contacts to easily populate your documents, as well as upload some useful files into your resources section for easy access.
                                    Our speciality is customization, allowing you to discount and markup your prices in anyway imaginable, and to stylize your PDF to your liking.
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionButton
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                p={4}
                                _expanded={{ bg: 'blue.500', color: 'white' }}
                            >
                                <Text fontSize="md">Do I need to book a demo?</Text>
                                <ChevronDownIcon fontSize="24px" />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Text color="gray.600">
                                    Absolutely not! We won't try and sell you on our software, we'd like the user experience to speak for itself. If you have any questions,
                                    feel free to reach out to us on the Contact Us page, but otherwise, you can sign up and start using RapidToolz today!
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionButton
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                p={4}
                                _expanded={{ bg: 'blue.600', color: 'white' }}
                            >
                                <Text fontSize="md">How much does RapidToolz cost?</Text>
                                <ChevronDownIcon fontSize="24px" />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Text color="gray.600">
                                    RapidToolz is currently in it's testing phase so it's free to use for the time being :) Once this phase is over and we release the
                                    final batch of updates and fixes, we will clearly display the cost breakdown on our Pricing page!
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionButton
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                p={4}
                                _expanded={{ bg: 'blue.700', color: 'white' }}
                            >
                                <Text fontSize="md">How do I create a custom quote?</Text>
                                <ChevronDownIcon fontSize="24px" />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Text color="gray.600">
                                    Each quote made with RapidToolz is a custom quote! You can start from scratch or use an existing one as a starting template.
                                    Whether it's text or tables, you can always tailor the layout and style of your quote to your liking!
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </VStack>
            </Flex>
            <Footer />
        </>
    )
}