import {
    Flex, Stack, Heading, useColorModeValue, useSteps, VStack, HStack, Button
} from "@chakra-ui/react"
import CompanyForm from "../components/SignUpCompany/CompanyForm"
import SubscriptionForm from "../components/SignUpCompany/SubscriptionForm"
import SetupForm from "../components/SignUpCompany/SetupForm"
import { FiArrowRight, FiArrowLeft } from 'react-icons/fi'
import MyStepper from '../components/MyStepper'
import { useSelector, useDispatch } from "react-redux"
import { useState } from "react"

export default function SignupCompany() {
    const dispatch = useDispatch()
    //  HR - this is the page where you sign up an account (contains 2 steps)
    const steps = [
        { title: 'Company Info' },
        { title: 'Billing Info' },
        { title: 'Account Setup' },
    ]
    const { error } = useSelector((state) => state.account)

    const [userData, setUserData] = useState({
        email: "",
        role: "owner"
    })

    const [companyData, setCompanyData] = useState({
        name: "",
        email: "",
        phone: "",
        fax: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        product_number: 1000000000,
        document_number: 1000000000,
        default_fob: " ",
        currency: "USD",
        default_valid_for: 15,
        default_terms_text: {
            text_1: "Terms Update: The payment conditions mentioned above may undergo modifications upon the successful credit approval from our credit department. The terms outlined in our overall sales agreement are integral to this quotation and take precedence in the event of any conflicting interpretations.",
            text_2: "We appreciate your interest in (Your Company Name). Feel free to reach out if you have any inquiries or require further assistance. We are here to help."
        },
        subscription: {
            status: "inactive",
        }
    })

    // HR - state variable used to track current step
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
    })

    function incrementStep() {
        switch (activeStep) {
            case 0:
                // HR - block the user from proceeding to the next step until form is filled out
                if (companyData.name !== "" && companyData.email !== "" && companyData.phone !== "" && companyData.address_1 !== "" && !error) {
                    setActiveStep(activeStep + 1)
                }
                break;
            default:
                setActiveStep(activeStep + 1)
                break;
        }
    }

    function decrementStep() {
        if (activeStep !== 0) {
            setActiveStep(activeStep - 1)
        }
    }

    return (
        <Flex
            minH={"145vh"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
        >
            <Stack spacing={8} mt={18} w={"4xl"} py={12} px={4}>
                <Stack align={"center"}>
                    <Heading fontSize={"4xl"} textAlign={"center"}>
                        Sign up
                    </Heading>
                    <Heading fontSize={"2xl"} textAlign={"center"}>
                        (Company)
                    </Heading>
                </Stack>
                <VStack spacing={10}>
                    <MyStepper activeStep={activeStep} steps={steps} />
                    <HStack spacing={"7rem"}>
                        {/* HR - dynamically display previous/next arrows depending on the current step */}
                        {activeStep > 0 && activeStep < 2 && <Button variant={"outline"} colorScheme={"blue"} onClick={decrementStep} leftIcon={<FiArrowLeft style={{ fontSize: "1.5rem" }} />}>Back</Button>}
                        {activeStep < 2 && <Button variant={"solid"} colorScheme="blue" onClick={incrementStep} rightIcon={<FiArrowRight style={{ fontSize: "1.5rem" }} />}>Next</Button>}
                    </HStack>
                    {activeStep === 0 && <CompanyForm incrementStep={incrementStep} companyData={companyData} setCompanyData={setCompanyData} userData={userData} setUserData={setUserData} />}
                    {activeStep === 1 && <SubscriptionForm incrementStep={incrementStep} userEmail={userData.email} />}
                    {activeStep === 2 && <SetupForm incrementStep={incrementStep} />}
                </VStack>
            </Stack>
        </Flex>
    )
}
