import { Stack, FormControl, FormLabel, Center, Image, Button, Input, HStack, Tooltip, useDisclosure, Text, VStack, Select } from "@chakra-ui/react"
import ImportContactsModal from '../SignUpCompany/ImportContactsModal'
import ImportProductsModal from '../SignUpCompany/ImportProductsModal'
import AddResourcePopup from "../AddResourcePopup"
import companyLogo2 from "../../images/default_logo.png"
import { FiInfo } from "react-icons/fi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import handleInputChange from "../../helper/inputChange";
import { updateAccount } from "../../redux/Account/accountCalls"
import { updateDocumentNumber, updateProductNumber } from "../../redux/Account/accountSlice"
import { getResources } from "../../redux/Resource/resourceCalls"

export default function ManageCompanyInfo(styles) {
    const { isOpen: isProductsOpen, onOpen: onProductsOpen, onClose: onProductsClose } = useDisclosure()
    const { isOpen: isContactsOpen, onOpen: onContactsOpen, onClose: onContactsClose } = useDisclosure()
    const { isOpen: isResourcesOpen, onOpen: onResourcesOpen, onClose: onResourcesClose } = useDisclosure()

    const { account_name } = useSelector((state) => state.account)
    const { account_email } = useSelector((state) => state.account)
    const { account_phone } = useSelector((state) => state.account)
    const { account_fax } = useSelector((state) => state.account)
    const { address_1 } = useSelector((state) => state.account)
    const { address_2 } = useSelector((state) => state.account)
    const { city } = useSelector((state) => state.account)
    const { state } = useSelector((state) => state.account)
    const { country } = useSelector((state) => state.account)
    const { zip } = useSelector((state) => state.account)
    const { currency } = useSelector((state) => state.account)
    const { product_number } = useSelector((state) => state.account)
    const { document_number } = useSelector((state) => state.account)
    const { account_id } = useSelector((state) => state.account)
    const { account_logo } = useSelector((state) => state.account)
    const { resources } = useSelector((state) => state.resources)
    const { createResourceProgress } = useSelector((state) => state.resources)
    const dispatch = useDispatch()
    const [currentLogo, setCurrentLogo] = useState(companyLogo2)

    const [companyIsDisabled, setCompanyIsDisabled] = useState(true)
    const [productNumberChanged, setProductNumberChanged] = useState(false)
    const [documentNumberChanged, setDocumentNumberChanged] = useState(false)
    const [companyInfo, setCompanyInfo] = useState({
        name: account_name,
        email: account_email,
        phone: account_phone,
        fax: account_fax,
        address_1: address_1,
        address_2: address_2,
        city: city,
        state: state,
        country: country,
        zip: zip,
        currency: currency,
        product_number: product_number,
        document_number: document_number
    })

    function inputChange(e) {
        if (e.target.name === "product_number") {
            setProductNumberChanged(true)
        }
        if (e.target.name === "document_number") {
            setDocumentNumberChanged(true)
        }
        handleInputChange(e.target, setCompanyInfo)
    }

    function handleSaveAccount() {
        setCompanyIsDisabled(true)
        updateAccount({ data: companyInfo, id: account_id }, dispatch)
        if (productNumberChanged) {
            dispatch(updateProductNumber({ mode: 'set', product_number: companyInfo.product_number }))
        }
        if (documentNumberChanged) {
            dispatch(updateDocumentNumber({ mode: 'set', document_number: companyInfo.document_number }))
        }
    }

    // HR - if an account logo is assigned, get the resource_url from the resources redux state to render the correct image on screen
    useEffect(() => {
        if (account_logo) {
            var logoResource = resources.find((resource) => {
                return resource._id === account_logo
            })
            setCurrentLogo(logoResource.resource_url)
        }
    }, [account_logo, resources])


    // HR - this is used to refetch the updated resources once a new resource is created
    useEffect(() => {
        getResources(account_id, dispatch)
    }, [createResourceProgress])

    return (
        <>
            <HStack justifyContent={'center'} mx={'2rem'} width={['40vw','60vw']}>
                <Stack m={'3rem'} w={'inherit'} spacing={'2rem'}>
                    <Text fontSize="2xl" fontFamily="heading" fontWeight="light">Manage Company Info</Text>
                    <FormControl>
                        <FormLabel>Company Logo</FormLabel>
                        <Stack direction={['column', 'row']} spacing={6}>
                            <Center>
                                <Image width={['10rem','15rem']} src={currentLogo} />
                            </Center>
                            <Center w="fit-content">
                                <VStack spacing={'1rem'}>
                                    <Button w="fit-content" colorScheme={"orange"} onClick={onResourcesOpen}>Change Logo</Button>
                                    {/* HR - switch button color and text depending on isDisabled state */}
                                    {companyIsDisabled ?
                                        <Button w="fit-content" colorScheme={"purple"} onClick={() => setCompanyIsDisabled(false)}>Edit Info</Button> :
                                        <Button w="fit-content" colorScheme={"green"} onClick={handleSaveAccount}>Save Info</Button>
                                    }
                                </VStack>
                            </Center>
                        </Stack>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Company Name</FormLabel>
                        <Input
                            style={companyIsDisabled ? styles.disabledInput : styles.normalInput}
                            value={companyInfo.name}
                            name="name"
                            onChange={inputChange}
                            type="text"
                            variant={"filled"}
                            disabled={companyIsDisabled}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Company Email</FormLabel>
                        <Input
                            style={companyIsDisabled ? styles.disabledInput : styles.normalInput}
                            type="email"
                            variant={"filled"}
                            value={companyInfo.email}
                            name="email"
                            onChange={inputChange}
                            disabled={companyIsDisabled}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Company Phone</FormLabel>
                        <Input
                            style={companyIsDisabled ? styles.disabledInput : styles.normalInput}
                            type="string"
                            name="phone"
                            variant={"filled"}
                            value={companyInfo.phone}
                            onChange={inputChange}
                            disabled={companyIsDisabled}
                        />
                    </FormControl>
                    <FormControl >
                        <FormLabel>Company Fax</FormLabel>
                        <Input
                            style={companyIsDisabled ? styles.disabledInput : styles.normalInput}
                            type="string"
                            name="fax"
                            variant={"filled"}
                            value={companyInfo.fax}
                            onChange={inputChange}
                            disabled={companyIsDisabled}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Address</FormLabel>
                        <Input
                            name="address_1" type="text" mb={4} value={companyInfo.address_1} onChange={inputChange}
                            style={companyIsDisabled ? styles.disabledInput : styles.normalInput} variant={"filled"} placeholder="Street Address" disabled={companyIsDisabled}
                        />
                        <Input
                            name="address_2" type="text" mb={4} value={companyInfo.address_2} onChange={inputChange}
                            style={companyIsDisabled ? styles.disabledInput : styles.normalInput} variant={"filled"} placeholder="Apartment Number" disabled={companyIsDisabled}
                        />
                        <Input
                            name="city" type="text" mb={4} value={companyInfo.city} onChange={inputChange}
                            style={companyIsDisabled ? styles.disabledInput : styles.normalInput} variant={"filled"} placeholder="City" disabled={companyIsDisabled}
                        />
                        <Input
                            name="state" type="text" mb={4} value={companyInfo.state} onChange={inputChange}
                            style={companyIsDisabled ? styles.disabledInput : styles.normalInput} variant={"filled"} placeholder="State" disabled={companyIsDisabled}
                        />
                        <Input
                            name="zip" type="text" mb={4} value={companyInfo.zip} onChange={inputChange}
                            style={companyIsDisabled ? styles.disabledInput : styles.normalInput} variant={"filled"} placeholder="Zip" disabled={companyIsDisabled}
                        />
                        <Input
                            name="country" type="text" value={companyInfo.country} onChange={inputChange}
                            style={companyIsDisabled ? styles.disabledInput : styles.normalInput} variant={"filled"} placeholder="Country" disabled={companyIsDisabled}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <HStack alignItems={'baseline'}>
                            <FormLabel>Currency</FormLabel>
                            <Tooltip label='This will only change the currency symbol being used and does NOT convert any prices or costs' fontSize='md' placement="auto-start">
                                <span><FiInfo /></span>
                            </Tooltip>
                        </HStack>
                        <Select name="currency" value={companyInfo.currency} variant={"filled"} onChange={inputChange} borderColor="#E0E1E7" disabled={companyIsDisabled}
                            style={companyIsDisabled ? styles.disabledInput : styles.normalInput}>
                            <option value='USD'>USD</option>
                            <option value='GBP'>GBP</option>
                            <option value='EUR'>EUR</option>
                        </Select>
                    </FormControl>
                    <FormControl isRequired>
                        <HStack alignItems={'baseline'}>
                            <FormLabel>Document ID Generation</FormLabel>
                            <Tooltip label='This number will be incremented to generate a unique id for each document' fontSize='md' placement="auto-start">
                                <span><FiInfo /></span>
                            </Tooltip>
                        </HStack>
                        <Input
                            style={companyIsDisabled ? styles.disabledInput : styles.normalInput}
                            type="number"
                            name="document_number"
                            variant={"filled"}
                            value={companyInfo.document_number}
                            onChange={inputChange}
                            disabled={companyIsDisabled}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <HStack alignItems={'baseline'}>
                            <FormLabel>Product ID Generation</FormLabel>
                            <Tooltip label='This number will be incremented to generate a unique id for each product' fontSize='md' placement="auto-start">
                                <span><FiInfo /></span>
                            </Tooltip>
                        </HStack>
                        <Input
                            style={companyIsDisabled ? styles.disabledInput : styles.normalInput}
                            type="number"
                            name="product_number"
                            variant={"filled"}
                            value={companyInfo.product_number}
                            onChange={inputChange}
                            disabled={companyIsDisabled}
                        />
                    </FormControl>
                    <Stack direction={['column', 'row']} spacing={['1rem','2rem','3rem','4rem','5rem']} justifyContent={'center'} mt={'2rem'}>
                        <Button
                            bg={'blue.400'}
                            color={'white'}
                            w={'fit-content'}
                            _hover={{
                                bg: 'blue.500',
                            }}
                            onClick={onProductsOpen}
                        >
                            Import Products
                        </Button>
                        <Button
                            bg={'orange.400'}
                            color={'white'}
                            w={'fit-content'}
                            _hover={{
                                bg: 'orange.500',
                            }}
                            onClick={onContactsOpen}
                        >
                            Import Contacts
                        </Button>
                    </Stack>
                </Stack>
            </HStack>
            <ImportProductsModal isOpen={isProductsOpen} onClose={onProductsClose} />
            <ImportContactsModal isOpen={isContactsOpen} onClose={onContactsClose} />
            <AddResourcePopup isOpen={isResourcesOpen} onClose={onResourcesClose} mode={'logo'} />
        </>
    )
}