import { Box, Button, Divider, Flex, HStack, useColorModeValue, useDisclosure } from "@chakra-ui/react"
import CarouselNew from "../components/CarouselNew"
import { FiFilePlus } from 'react-icons/fi'
import AddResourcePopup from "../components/AddResourcePopup"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getResources } from "../redux/Resource/resourceCalls"

export default function Resources() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { account_id } = useSelector((state) => state.account)
    const { createResourceProgress } = useSelector((state) => state.resources)
    const dispatch = useDispatch()

    // HR - this is used to refetch the updated resources once a new resource is created
    useEffect(() => {
        getResources(account_id, dispatch)
    }, [createResourceProgress])

    return (
        <Flex
            minH={"100vh"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
        >
            <Box width={"80%"} >
                <HStack style={{ marginTop: "5rem", justifyContent: "center" }} spacing={"5rem"}>
                    <Button variant={"solid"} size={'lg'} colorScheme={"blue"} onClick={onOpen} leftIcon={<FiFilePlus style={{ fontSize: "1.5rem" }} />}>Upload Resource</Button>
                </HStack>
                <Divider pb={'5rem'} />
                <CarouselNew />
            </Box>
            <AddResourcePopup isOpen={isOpen} onClose={onClose} mode={'normal'} />
        </Flex>
    )
}