import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    RadioGroup,
    Stack,
    Radio,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Button,
    VStack,
    HStack
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { createProduct, getLastProductId } from '../redux/Product/productCalls'
import { useDispatch, useSelector } from 'react-redux';
import handleInputChange from '../helper/inputChange';
import { toast } from 'react-toastify';

export default function AddProductPopup({ isOpen, onClose }) {
    const dispatch = useDispatch();
    const { account_id } = useSelector((state) => state.account)
    const { product_number } = useSelector((state) => state.account)
    const [newProduct, setNewProduct] = useState({
        product_id: "",
        name: "",
        description: "",
        category: "",
        list_price: null,
        cost: null,
        account_id: account_id
    })
    const [generationMode, setGenerationMode] = useState('auto')
    const { products } = useSelector((state) => state.products)
    const productIds = products.map((product) => product.product_id)
    const { product_number_status } = useSelector((state) => state.account)

    function handleAddProduct(e) {
        e.preventDefault()
        if (newProduct.name !== "") {
            createProduct(newProduct, dispatch)
        }
            onClose()
        setNewProduct({
            product_id: "",
            name: "",
            description: "",
            category: "",
            list_price: null,
            cost: null,
            account_id: account_id
        })
        setGenerationMode('auto')
    }

    function inputChange(e) {
        handleInputChange(e.target, setNewProduct)
    }

    useEffect(() => {
        // HR - if the most recent product_number has been fetched, automatically set product_id to that value + 1
        setNewProduct((newProduct) => ({ ...newProduct, product_id: String(parseInt(product_number) + 1) }))
    }, [product_number])


    useEffect(() => {
        // HR - if product number was not recently set in settings, use number fetched from last product to increment
        if (product_number_status !== "updated" && isOpen) {
            const getLastIdFn = async () => { getLastProductId(account_id, dispatch) }
            getLastIdFn()
        }
        // HR - if user switches back to auto generation, reset product_id
        if (generationMode === "auto") {
            setNewProduct((newProduct) => ({ ...newProduct, product_id: parseInt(product_number) + 1 }))
        }
    }, [generationMode, isOpen])

    useEffect(() => {
        if((productIds.includes( newProduct.product_id))){
            toast.error('That product id has already been used!')
        }
    }, [newProduct])

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add New Product</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <form onSubmit={handleAddProduct}>
                            <VStack spacing={"1rem"}>
                                <FormControl>
                                    <FormLabel>Product ID Generation</FormLabel>
                                    <RadioGroup value={generationMode} onChange={(val) => setGenerationMode(val)}>
                                        <Stack spacing={5} direction='row'>
                                            <Radio colorScheme='green' value='auto'>
                                                Auto
                                            </Radio>
                                            <Radio colorScheme='red' value='manual'>
                                                Manual
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                </FormControl>
                                <FormControl isRequired>
                                    <FormLabel>Product ID</FormLabel>
                                    <Input placeholder='Product ID' name="product_id" value={newProduct.product_id} onChange={inputChange} disabled={generationMode === "auto" ? true : false} />
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Name</FormLabel>
                                    <Input placeholder='Product Name' name="name" value={newProduct.name} onChange={inputChange} />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Description</FormLabel>
                                    <Input placeholder='Product Description' name="description" value={newProduct.description} onChange={inputChange} />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Category</FormLabel>
                                    <Input placeholder='Category' name="category" value={newProduct.category} onChange={inputChange} />
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Cost</FormLabel>
                                    <Input placeholder='15.00' type='number' name="cost" value={newProduct.cost} onChange={inputChange} />
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>List Price</FormLabel>
                                    <Input placeholder='20.00' type='number' name="list_price" value={newProduct.list_price} onChange={inputChange} />
                                </FormControl>
                                <HStack>
                                    <Button onClick={onClose}>Cancel</Button>
                                    <Button colorScheme='blue' my={3} mx={5} type="submit">
                                        Save
                                    </Button>
                                </HStack>
                            </VStack>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}